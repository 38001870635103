.c-cart {
  .form-control {
    border: none;
    border-radius: 0.5rem;
  }

  &__quantity {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    ons-button {
      min-width: 40px;
      margin-left: 0;
      margin-right: 0;
      background-color: transparent;
      box-shadow: none !important;
      color: #000000;
    }

    svg {
      align-self: center;
    }

    &-count {
      line-height: 1;
    }
  }

  &__product {
    ons-button {
      padding: 0;
      background-color: transparent;
      color: $secondary;
    }

    ul {
      padding-left: 0;
      margin-top: .1rem;
    }

    li {
      list-style: none; 
      font-size: .8rem;
    }
  
    &-extras {

      h3 {
        color: #000000;
        margin-bottom: 0;
      }

      h4 { 
        color: #000000;
      }

      background-color: rgba(255, 255, 255, 0.9);
      margin: 1rem !important;
      padding: 1rem;
      border-radius: 0.5rem;
      overflow-y: scroll;
      color: #000000;
      height: 100%;
    }
  }

  &__favourite {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.5rem 0 0;

    ons-checkbox {
      margin-right: .5rem;
    }
  }

  .list {
    background-image: none;
  }
} 

.c-cart__shop.card {
  background-color: rgba($white, 0.9) !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  padding: 1.5rem;
  margin: 1rem 0;

  h3 {
    margin: 0 1rem 1rem 0;
  }
}

.c-cart__buttons.purchase {

  margin: 2rem 0;
  .c-btn {
    width: 100%;
  }
}

ons-modal {
  overflow-y: scroll;

  .modal__content {
    margin-top: 1rem;
    margin-bottom: 1rem;

    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.c-modal__edit-order {
  padding: 3rem 0;
  h4 {
    font-weight: normal;
    font-size: 0.875rem;
    margin: 0.25rem 0 0;
  }
  .c-drink__sizes {
    padding: 0 0 0.5rem;
  }
  .check-button {
    min-width: 6rem;
  }
  .c-drink__extras-milk, .c-drink__extras-strength, .c-drink__extras-other {
    margin: auto;
    max-width: 25rem;
    padding: 0;
    .btn-group-toggle {
      justify-content: center;
    }
  }

  .c-drink__extras-strength {
    justify-content: center;
    margin-top: 0.25rem;
    margin-bottom: 1.25rem;
  }

  textarea {
    font-size: large;
  }
}

.c-cart__quantity {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  height: 1rem;
  ons-button {
    height: 1.25rem;
    min-width: 1.75rem;
    text-align: center;
    display: flex;
  }
  span {
    height: 1rem;
    min-width: 1rem;
    text-align: center;
  }
  svg {
    transform: scale(0.65);
  }
}

.c-drink__extras-milk, .c-drink__extras-strength, .c-drink__extras-other {
  border-top: 1px solid #999;
  padding-top: 1rem !important;

  .c-modal__edit-order & {
    padding-bottom: 1rem;
  }

  h3 {
    text-align: center;
  }

  .btn-group-toggle {
    ons-row {
      justify-content: center;
    }

    ons-col {
      width: 33.33%;
      flex-basis: 33.33%;
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      justify-content: center;

      .check-button {
        white-space: initial;
      }
    }
  }
}