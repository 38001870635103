.button {
  background-color: $black;
  color: white;
  border-radius: 2rem;
  box-shadow: none;
  text-transform: none;

  &--secondary {
    background-color: $gray-dark;
  }

  &:active {
    opacity: 1;
  }
}

.button--cta {
  &:active {
    opacity: 1;
  }
}

.button--large--cta {
  background-color: $black;
  color: #FFFFFF;
  border-radius: 2rem;

  &--secondary {
    background-color: $gray-dark;
  }

  &:active {
    opacity: 1;
  }
}

.c-btn {
  text-align: center;
  border: 1px solid #000;
  box-shadow: none;
  padding: 0.75rem 1.25rem;
  line-height: 1;
  max-width: 100%;
  min-width: 7rem;
  text-transform: none;
}
.c-btn.white {
  background-color: #fff;
  color: #000;
  border: 1px solid #666;
}

.c-btn.gold {
  background-color: #96754A !important;
  border: none;
}

.c-btn__reset {
  min-width: 6rem;
}

.c-btn__next {
  background-color: #000;
  color: #fff;
  min-width: 6.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.c-btn__purchase {
  margin: auto;
}

.c-btn__text {
  text-decoration: underline;
  background-color: transparent;
  color: #000;
  border: none;
}

.view-toggle-container {
  position: absolute;
  right: 1.5rem;
  width: 52px;
  height: 52px;
}

.button--view-toggle {
  position: fixed;
  bottom: 1.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
}

.btn-group-toggle {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  :disabled + .radio-button--material__checkmark {
    opacity: 0;
  }
}

.c-btn__toggle-layout {
  display: inherit;
  position: absolute;
  right: 0.65rem;
  background-color: transparent;
  top: 1rem;
  border-radius: 0;
  padding: 1rem;
  svg {
    transform: scale(1.5);
    path {
      stroke: #666;
      stroke-width: 0.5px;
      fill: transparent;
    }
  }
}

.c-btn__toggle-layout.c-btn__hide {
  display: none;
}

.c-btn__toggle-layout.c-drink__list {
  svg {
    
    path {
      
    }
  }
}

.check-button {
  background-color: $secondary !important;
  border: 1px solid #BDBDBD;
  margin: .2rem;
  color: white !important;
  border: 1px solid $secondary;
  background-color: #fff !important;
  min-width: 6em;
  border: none;
  color: $dark !important;
  text-align: center;
}

.check-button--selected {
  border: none;
  min-width: 6em;
  text-align: center;
  background-color: $secondary !important;
  color: #fff;
  margin: .2rem;
  // background-color: $secondary-dark !important;
  // border: 1px solid $secondary-dark;
  // margin: .2rem;
  // color: $primary !important;
}

.check-button__text {
  display: inline-block;
  margin: 0.25rem 0 0;
  font-size: 0.9rem;
}

.cart-button {
  #svg__cup-with-steam {
    height: 3.25rem;
    margin: 0.75rem 0 0;
    polygon {
      fill: $brown;
    }
    path {
      stroke: #000;
      fill: transparent;
    }
    #steam path {
      stroke-width: 2px;
    }
    #cup {
      polygon {
        display: none;
      }
      path {

      }
    }
  }
}

.c-button__icon-num {
  color: #fff;
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  background-color: rgba(0,0,0,0.65);
  background-color: #383838;
  border-radius: 50%;
  height: 1em;
  width: 1em;
  padding: 0.15em;
  margin: 0 !important;
  font-size: 0.65rem;
  z-index: 10;
  line-height: 1;
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.c-current-order-directions {
  margin: 1rem 0;
  .c-btn {
    font-size: 0.938rem;
  }
}

.c-btn__sync {
  position: absolute;
  left: calc(50% - 28px);
  top: 50px;
  animation: appear 350ms linear;
  z-index: 999;

  .button {
    display: flex;
    padding: 1rem;
    
    svg {
      width: 26px;
    }
  }

  @keyframes appear {
    0% {
      top: -10px;
      opacity: 0;
    }

    50% {
      top: 0;
      opacity: 0.5;
    }
  
    100% {
      top: 10px;
      opacity: 1;
    }
  }
}

