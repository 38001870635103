.c-cafelist {
  padding: 1rem;

  ons-list-item {
    padding-left: .7rem;
    padding-right: .7rem;
    background-color: rgba(255,255,255,0.75);
    border-radius: 0.25em;
    margin: 0 0 0.5em 0;
  }
  
  &__header {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    &__button {
      width: 100%;
      text-align: center;
      border-radius: 2rem;
    }

    .button:first-child {
      margin-right: 0.5rem;
    }

    .button:last-child {
      margin-left: 0.5rem;
    }

    &__button--not-selected {
      background-color: transparent !important;
      border: 1px solid $black !important;
      color: $primary !important;
    }

    &__button--selected {
      background-color: $black !important;
      border: 1px solid $black !important;
      color: $white !important;
    }
  }

  .list {
    background-image: none;
  }

  .list-item {
    &:not(:last-child) {
      .list-item__center {
        // border-bottom: 1px solid rgba($secondary, 0.25);
      }
    }
  }

  .list-item__center {
    display: flex;
    flex-wrap: nowrap;
  }

  &__item {
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;

    &__name {
      margin: .1rem;
      margin-bottom: .2rem;
      font-weight: bold;
    }

    &__closed {
      margin: .1rem;
      font-weight: bold;
      color: $red;
    }

    &__temporarily-closed {
      margin: .1rem;
      font-size: smaller;
      font-weight: bold;
      color: $red;
    }

    &__address {
      margin: .1rem;
    }

    &__description {
      margin: .1rem;
      color: $gray-darker;
    }

    &__image {
      flex-shrink: 0;
      width: 60px;
      height: 60px;
      object-fit: contain;
      border-radius: 8px;
      margin-left: 1rem;
    }
  }  
}

.c-cafelist__item--highlight {
  background-color: #FFFFFF;
  border-radius: .3rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;

  .list-item:not(:last-child) .list-item__center {
    border-bottom: 2px solid #FFFFFF !important;
  }
}

.cafelist__bean {
  color: $primary;
  font-style: italic;
  font-weight: normal;
}