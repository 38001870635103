.c-current-order {
  
  &__items {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #000;

    p {
      margin-top: 0.25rem;
      text-align: center;
    }

    &-cafe {
      font-size: 1.25rem;
      margin-bottom: .1rem;
    }

    &-time {
      font-size: 1.1rem;
    }
  }

  &__item {
    margin-bottom: .8rem;
    margin-left: 1rem;
    margin-right: 1rem;

    p {
      font-size: 1.1rem;
      text-align: left;
      margin-bottom: .1rem;
      margin-top: 0;     
    }
    
    ul {
      margin-top: .1rem;
      padding-left: 0;
      margin-bottom: .3rem;        
    }

    li {
      color: #000000;
      list-style: none;
      font-size: .8rem;
      text-align: left;
    }
  }

  &-directions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__order-no {
    margin-top: 0 !important;
    margin-bottom: 0.2rem !important;
  }
}


.c-current-order-banner {
  .react-swipeable-view-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 1rem 2rem 0;
    div {
      max-width: 90%;
      margin: auto;
    }
    .c-current-order-banner__card {
      z-index: 99999;
      max-height: 100px;
      background-color: rgba(255, 255, 255, 0.95) !important;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding: 1rem 1rem 0.5rem 1rem;
  
      p {
        margin: 0;
        font-size: 0.875rem;
      }
    
      .c-home--map & {
        position: absolute;
    
        + .c-favourate-cafes {
          margin-top: 100px;
        }
      }  

      .c-current-order-banner__card-cta {
        text-align: right;
        margin-top: 0.25rem;
        font-weight: 500;
        color: #a98556;
      }
    }
    .c-slick-card {
      padding: 0.5rem;
      max-width: 100%;
      margin: auto;
    }
  }
}


