.c-login__logo {
  display: block;
  background-color: #fff; 
  padding-left: 2rem;
  padding-right: 2rem;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.c-login__reset {
  text-align: center;
  color: #000;
  text-decoration: none;
}