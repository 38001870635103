.c-add-to-home {
  padding: 0;
  background-color: $gray-lighter;
  border-radius: 1rem;
  color: #000;

  h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
  }

  hr {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    border-top: 1px solid $gray;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.c-add-to-home__close {
  position: absolute;
  top: 0.75rem;
  right: 1.25rem;
}

.c-add-to-home__logo {
  width: 60px;
  margin: 1rem;
  border-radius: 12px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
}

.c-add-to-home__icon {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.c-add-to-home__content {
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: 1.5rem;
}

.c-add-to-home__cta {
  padding: 0.5rem 0.75rem;
  border: 1px solid #000;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-add-to-home__ellipsis {
  width: 30px;
  border-radius: 8px;
  margin-left: 0.75rem;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
  background-color: rgb(245, 249, 254);
  border: 2px solid $white;
}

.c-add-to-home__install-chrome {
  width: 200px;
  border-radius: 8px;
  padding: 3px;
  margin-left: 0.75rem;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
}

.c-add-to-home__install-safari {
  width: 85%;
  border-radius: 8px;
  padding: 3px;
  margin-top: 0.75rem;
  margin-left: 0.75rem;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
}

.c-add-to-home__share {
  width: 40px;
  border-radius: 8px;
  margin-left: 0.75rem;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid $white;
}