.u-text-center {
  text-align: center !important;
}

.u-text-left {
  text-align: left !important;
}

.u-text-right {
  text-align: right !important;
}

.u-text-strong {
  font-weight: bold !important;
}

.u-text-smaller {
  font-size: 14px;
}

.u-text-large {
  font-size: 30px;
}

.u-text-italic {
  font-style: italic;
}

.u-text-decoration-none {
  text-decoration: none;
}

.u-capitalize {
  text-transform: capitalize;
}

.u-align-items-center {
  align-items: center;
}

.u-justify-content-center {
  justify-content: center;
}

.u-justify-content-end {
  justify-content: end;
}

.u-justify-content-between {
  justify-content: space-between;
}

.u-justify-content-evenly {
  justify-content: space-evenly;
}

.u-h-0 {
  height: 0;
}

.u-mb-0 {
  margin-bottom: 0 !important;
}

.u-m-1 {
  margin: 1rem !important;
}

.u-mb-1 {
  margin-bottom: 1rem !important;
}

.u-mb-15 {
  margin-bottom: 1.5rem !important;
}

.u-mb-2 {
  margin-bottom: 2rem !important;
}

.u-mb-3 {
  margin-bottom: 3rem !important;
}

.u-m-0 {
  margin: 0 !important;
}

.u-mt-0 {
  margin-top: 0 !important;
}

.u-mt-05 {
  margin-top: 0.5rem !important;
}

.u-mt-1 {
  margin-top: 1rem !important;
}

.u-mt-15 {
  margin-top: 1.5rem !important;
}

.u-mt-2 {
  margin-top: 2rem !important;
}

.u-mt-3 {
  margin-top: 3rem !important;
}

.u-ml-0 {
  margin-left: 0 !important;
}

.u-ml-05 {
  margin-left: 0.5rem !important;
}


.u-ml-1 {
  margin-left: 1rem !important;
}

.u-ml-05 {
  margin-left: .5rem !important;
}

.u-ml-025 {
  margin-left: 0.25rem !important;
}

.u-mr-0 {
  margin-right: 0 !important;
}

.u-mr-025 {
  margin-right: 0.25rem !important;
}

.u-mr-04 {
  margin-right: 0.4rem !important;
}


.u-mr-05 {
  margin-right: .5rem;
}

.u-mr-075 {
  margin-right: .75rem;
}

.u-mr-1 {
  margin-right: 1rem;
}

.u-mr-15 {
  margin-right: 1.5rem;
}

.u-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.u-p-0 {
  padding: 0 !important;
}

.u-p-1 {
  padding: 1rem;
}

.u-px-15 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.u-pt-1 {
  padding-top: 1rem !important;
}

.u-pl-05 {
  padding-left: 0.5rem !important;
}

.u-pr-05 {
  padding-right: 0.5rem !important;
}

.u-bg {
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -1rem;
  padding: 1rem;
}

.u-bg--white {
  background-color: white;
}

.u-bg--gray {
  background-color: rgba($gray-light, 0.7);
  color: $gray-darker;
}

.u-bg--red {
  background-color: $red  !important;
}

.u-bg--green {
  background-color: $green  !important;
}

.u-bg--transparent {
  background-color: transparent !important;
}

hr {
  border: 0;
  border-top: 3px solid $primary;
  width: 60%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.u-width-100 {
  width: 100%;
}

.u-width-30 {
  width: 30%;
}

.u-width-40 {
  width: 40%;
}

.u-width-50 {
  width: 50%;
}

.u-width-60 {
  width: 60%;
}

.u-width-80 {
  width: 80%;
}

.u-text-black {
  color: #000 !important;
}

.u-text-gray {
  color: #999;
}

.u-text-white {
  color: #fff !important;
}

.u-text-red {
  color: red !important;
}

.u-d-flex {
  display: flex;
}

.u-d-flex-row-reverse {
  flex-direction: row-reverse;
}

.u-d-flex-col {
  display: flex;
  flex-direction: column;
}

.u-flex-shrink-0 {
  flex-shrink: 0;
}

.u-flex-shrink-1 {
  flex-shrink: 1;
}

.u-flex-grow-0 {
  flex-grow: 0;
}

.u-flex-grow-1 {
  flex-grow: 1;
}

.u-minwidth-auto {
  min-width: auto !important;
}

.u-minwidth-7 {
  min-width: 7rem;
}

.u-width-fit {
  width: fit-content;
}

.u-relative {
  position: relative !important;
}