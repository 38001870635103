.c-map__card-image {
  width: 100%;
  height: 80px;
  object-fit: cover;
}

.c-map__card {
  width: 264px;

  .c-map__card-title {
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > a {
      text-decoration: none;
      color: $black;
      border: none;
    }
  }
}

.c-map__card-content {
  padding: 1rem;
  overflow: hidden;
}

.c-cafelist__bean {
  color: $primary;
  font-style: italic;
  font-weight: normal;
}

.gm-style .gm-style-iw-c {
  padding: 0;
}