.c-quickorder {
  padding-right: 0;

  &__button-group {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  &__amount {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 0;
  }

  &__button {
    // width: 100%;
    text-align: center;
    margin-top: 0.5rem;
  }
  
  &__order {
    width: 100%;
    display: flex;
    flex-direction: column;
    
    &-shop {
      font-weight: bold;
      margin-bottom: .1rem;
    }

    &-product {
      font-size: .9rem;
      font-weight: 500;
    }

    &-extras {
      display: flex;
      flex-direction: row;
      align-content: flex-start;
      width: 95%;
      flex-wrap: wrap;
      margin-bottom: .5rem;
    }

    &-extra {
      font-size: .8rem;
    }
  }
}