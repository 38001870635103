.c-timer__order-number {
  color: $gray-darker;
  text-align: center;
}

.c-timer__heading {
  text-align: center;
  margin-bottom: 0;  
  color: $gray-darker;
}

h2.c-timer__order-number,
h3.c-timer__heading {
  color: $gray-darker;
  margin-bottom: 0;  
}

.c-timer {
  position: relative;
  width: 300px;
  height: 300px;
  margin-left: auto;
  margin-right: auto;

  &:before {
    content: '';
    position: absolute;
    display: block;
    left: 27px;
    right: 27px;
    top: 27px;
    bottom: 27px;
    background-color: $dark;
    border: 5px solid $light;
    border-radius: 50%;
  }

  ons-progress-circular {
    width: 100%;
    height: 100%;
  }  
  
  .progress-circular__primary {
    stroke: $dark;
    stroke-width: 2%;
  }

  .c-timer__ready {
    position: absolute;
    left: 0;
    top: 60px;
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    color: $gray;
  }

  .c-timer__countdown {
    position: absolute;
    left: 0;
    bottom: 130px;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 1.2rem;
  }

  .c-timer__mins,
  .c-timer__secs {
    font-size: 2rem;
  }
}
