.c-cafe {

  &__banner {
    width: calc(100% + 2rem);
    height: 160px;
    object-fit: cover;
    margin-left: -1rem;
    margin-top: -1rem;
    margin-bottom: 0.5rem;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.6);

    + h1 {
      position: absolute;
      top: -1.1rem;
      width: 100%;
      margin-left: -1rem;
      margin-right: -1rem;
      padding-top: 1rem;
      padding-bottom: 0.5rem;
      background-color: rgba(255, 255, 255, 0.9);
    }
  }

  &__logo {
    position: absolute;
    top: 0;
    max-width: 5em;
  }

  &__drinks {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    
    &__sold-out {
      margin-top: .5rem;
      margin-left: 1.4rem !important;
      color: red;
    }

    &__item.card {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 0;
      width: 100%;
      padding: 0 0 1.5rem;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      &__text {
        padding: 0 0 0 8rem;
      }

      ons-icon {
        display: none;
      }
    }
  }

  &__drink-image {
    max-width: 30px;
    height: auto;
    margin-top: 0 !important;
  }
}