@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

@font-face {
    font-family: 'Avenir';
    src: url('../fonts/Avenir\ Light.ttf') format('truetype'); /* Safari, Android, iOS */
}


.page__content h1, .page__content h2, .page__content h3, .page__content h4, .page__content h5 {
    margin: 1rem 0;
}

.page,
.list,
.card {
  font-weight: 300;
}

.bold {
    font-weight: bold !important;
    letter-spacing: -0.01rem;
}

.c-text__header {
    padding: 0 0 1rem;
    h1 {
        margin: 0 0 0.125rem 0;
        padding: 0;
        line-height: 1;
        color: #000;
    }
    h2 {
        font-size: 1.25rem;
        margin: 0;
        padding: 0;
    }
}

.c-text__header.home {
    padding: 0.5rem 1.5rem 0;
}

.c-text__header.filter {
    padding: 0 1.5rem 0;
}

.c-form--order .c-text-header {
    margin: 0 2rem 0 0;
}

.page__content .c-form h3 {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
 }