.c-favourate-cafes {

  margin: 16px;
  margin-bottom: 0;

  .c-home--map & {
    position: absolute;
    z-index: 9999;
    width: calc(100% - 32px);
  }

  &__image,
  &__items__item__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  &__title {
    font-size: 1rem; 
    font-weight: bold;
    margin: 0;
    margin-bottom: 1rem;
  }

  &__items {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &__items__item.card {
    width: 30%;
    padding: 0;

    &:not(:last-child) {
      margin-right: 5%;
    }
  }

  &__items__item__name {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
    font-size: 0.9rem;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .card--material {
    border-radius: 1rem !important;
  }
}