.c-modal {
  background-color: rgba(255, 255, 255, 0.9);
  margin: 1rem !important;
  padding: 1rem;
  border-radius: 0.5rem;
  overflow-y: scroll;
  color: #000000;
  height: 340px;

  textarea {
    width: 80%;
    height: 200px;
    font-size: large;
  }
}

.c-modal--lg {
  height: fit-content;
  max-height: 100%;
}

.c-modal--notification-dialog {
  padding: 1.5rem;
  text-align: center;

  p:last-child {
    margin-bottom: 0;
  }
}

.c-modal--notification-dialog__close {
  position: absolute;
  top: 0.75rem;
  right: 1.25rem;
}

.c-modal--notification-dialog__allow-notifications {
  width: 80px;
  border-radius: 0.5rem;
}