.c-drink {

  &__cafe {
    font-size: .9rem;
    text-align: center;
    margin-bottom: 0;
  }

  &__add-to-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2rem 0 1rem;
    
    ons-button, button {
      margin: .3rem;
    }
  }
}

.card.c-cafe__drinks__item {
  opacity: 1; 
  max-height: 9rem;
  overflow: hidden; 
  padding: 0 0 1.5rem;
}

.card.c-cafe__drinks__item--not-selected {
  // transition: all 900ms ease-in-out;
  animation: shrinkAndHide 500ms ease-in-out forwards;
}

.card.c-cafe__drinks__item:active, .card.c-cafe__drinks__item:focus {
  .c-cafe__drinks__text {
    color: #96754A;
  }
}


.c-drink__grid {
  .c-cafe__drinks {
    justify-items: flex-start;
    align-items: flex-start;
    margin: 0 -1rem;
  }

  .c-cafe__drinks__sold-out {
    text-align: center !important;
    margin-left: 0 !important;
  }

  .c-cafe__drinks__item {
    display: flex !important;
    flex-direction: column !important;
  }

  .c-cafe__drinks__image {
    margin: 0 !important;
  }
  
  .c-cafe__drinks__text {
    text-align: center !important;
  }

  .c-cafe__drinks__item.card:not(.c-cafe__drinks__item--selected) {
    width: 15rem;
    max-width: 50%;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: .7rem;

    .c-cafe__drinks__image {
      margin: 0.5rem 0.5rem 0.5rem 0;
      min-height: 5rem;
      max-height: 5rem;
      min-width: 5rem;
      max-width: 5rem;
    }
    .c-cafe__drinks__text {
      font-size: 0.938rem;
      margin: 0.5rem 0;
      text-align: left;
      width: 8rem;
    }
  }
  @media all and (min-width: 450px) {
    .c-cafe__drinks {
      margin: 0;
      justify-items: flex-start;
      align-items: flex-start;
    }
    .c-cafe__drinks__item.card:not(.c-cafe__drinks__item--selected) {
      .c-cafe__drinks__image {
        min-height: 6rem;
        max-height: 6rem;
        min-width: 6rem;
        max-width: 6rem;
      }
      .c-cafe__drinks__text {
        font-size: 1rem;
      }
    }
  }
}

@keyframes shrinkAndHide {
  0%   {
    padding: 0 0 1.5rem; 
    opacity: 1; 
  }
  30% {   
    opacity: 0.5; 
  }
  100% {  
    opacity: 0; 
    padding: 0; 
    max-height: 0; 
    overflow: hidden; 
    margin-bottom: 0; 
  }
}

.c-cafe__drinks__text {
  margin: 2.5rem 1rem 0 1.25rem;
  font-weight: 400;
  font-size: 1.125rem;
}

.c-cafe__drinks__image {
  background-color: rgba(255, 255, 255, 0.75) !important;
  background-position: center center;
  background-size: 11rem;
  background-repeat: no-repeat;
  border: 3px solid rgba(255,255,255,0.85);
  border-radius: 4rem;
  min-height: 6rem;
  max-height: 6rem;
  min-width: 6rem;
  max-width: 6rem;
  box-shadow: 4px 2px 5px rgb(0 0 0 / 15%);
}

.c-cafe-drink__Latte {
  background-image: url(../../images/drink_latte.png);
}
.c-cafe-drink__Long {
  background-image: url(../../images/drink_black.png);
}
.c-cafe-drink__Mocha, .c-cafe-drink__Mochaccino {
  background-image: url(../../images/drink_mocha.png);
}
.c-cafe-drink__Flat {
  background-image: url(../../images/drink_flat-white.png);
}
.c-cafe-drink__Chai {
  background-image: url(../../images/drink_chai.png);
}
.c-cafe-drink__Capuccino, .c-cafe-drink__Cappuccino, .c-cafe-drink__Cappucino {
  background-image: url(../../images/drink_cappuccino.png);
}
.c-cafe-drink__Americano, .c-cafe-drink__Decaf {
  background-image: url(../../images/drink_americano.png);
}
.c-cafe-drink__Hot {
  background-image: url(../../images/drink_hot-choc.png);
}
.c-cafe-drink__Fluffy {
  background-image: url(../../images/drink_fluffy.png);
}
.c-cafe-drink__Green, .c-cafe-drink__Tea, .c-cafe-drink__Green, .c-cafe-drink__Syrup {
  background-image: url(../../images/drink_green-tea.png);
}
.c-cafe-drink__Tea {
  background-image: url(../../images/drink_tea.png);
}

.c-drink__extras {

  ons-checkbox {
    display: none;
  }

  ons-radio {
    display: none;
  }
  
  .check-button {
    background-color: rgba(255, 255, 255, 0.5) !important;
  }  
}

.c-drink__sizes {
  background-color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-flow: row nowrap;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 0.25em;
  padding: 0 0 2rem;
  height: 9rem;

  .c-drink__sizes__item {
    padding: 0;
    ons-button {
      box-shadow: none !important;
      background-color: transparent;
      color: rgba(250,250,250,0.85);
      color: #666;
      height: 100%;
      padding: 1rem;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center calc(100% - 2.25em);
      min-width: 5.5rem;
      text-align: center;
    }
    .c-drink__sizes__Medium, .c-drink__sizes__M {
      min-width: 6rem;
    }
    .c-drink__sizes__Large, .c-drink__sizes__L {
      min-width: 6.5rem;
    }
    .svg_cup polygon {
      fill: #fff;
    }
    .svg_cup path {
      stroke: #333;
      stroke-width: 1px;
    }
    .c-drink__size__text {
      visibility: hidden;
      width: 1rem;
      margin: -3.25rem auto 0;
    }
  
    .c-drink__size__text::first-letter {
      visibility: visible;
      margin: 0 0 0 0.1rem;
      color: #333;
    }
  }

  .c-drink__sizes__item--selected {
    .svg_cup polygon {
      fill: $brown;
    }
    .c-drink__size__text::first-letter {
      color: #fff;
    }

  }
}

.c-drink__extras-strength {
  display: flex;
  flex-flow: row wrap;
  h3 {
    min-width: 100%;
    margin: 0;
  }
  .range {
    border: 1px solid #555;
    border-radius: 1rem;
    height: 1rem;
    min-width: 100%;
    margin: 1rem 0 0;
    background-color: #fff;
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(150,117,74,1) 80%);
  }
  .range__input {
    height: 1rem;
  }
  .range__input, .range--material__input {
    background: transparent;
  }
  .range__labels {
    font-size: 0.938rem;
    font-weight: 300;
    min-width: 100%;
    margin: 0.25rem 0 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}

.check-button--selected {
  background-color: #fff !important;
  color: #222 !important;
  border: 1px solid #cecece;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.1));
}

.c-drink__extras-milk, .c-drink__extras-other {
  .check-button:before, .check-button--selected:before {
    padding: 0.75rem 0;
    width: 1.5rem;
    margin: auto;
    border-radius: 50%;
    content: "";
    background-color: #fff;
    border: 2px solid #fff;
    box-shadow: 0 0 1px 1px #555;
    display: block;
  }
  .check-button {
    background-color: transparent !important;
    font-size: 0.938rem;
    font-weight: 300;
    line-height: 1.65em;
    // min-width: auto;
    min-height: 4rem;
    text-align: center;
    margin: 0.75rem 0 0;
  }
  .check-button--selected:before {
    background-color: $brown;
    border: 2px solid #fff;
  }
  .check-button--selected {
    background-color: transparent !important;
    border: none;
    filter: none;
  } 
}

.incrementable_extras {
  .button {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    text-align: left;
    margin: 0;
    ons-radio {
      display: block;
      width: 100%;
      opacity: 1;
    }
  }
  .button:before {
    display: none;
  }
  .incrementor-radio:before {
    padding: 0.75rem 0;
    width: 1.5rem;
    margin: auto;
    border-radius: 50%;
    content: "";
    background-color: #fff;
    border: 2px solid #fff;
    box-shadow: 0 0 1px 1px #555;
    display: block;
    position: absolute;
    right: 9rem;
  }
  .incrementor-radio.selected:before {
    background-color: $brown;
    border: 2px solid #fff;
  }
  
  // .check-button--selected {
  //   background-color: transparent !important;
  //   border: none;
  //   filter: none;
  // }
}